import OpenInfo from "../../modules/utils/utils-js/theme/ts/utils/OpenInfo";
import Utils from "../../modules/utils/utils-js/theme/ts/utils/Utils";
import ObjectFit from "../../modules/utils/utils-js/theme/ts/utils/ObjectFit";
import Form from '../../modules/core/forms/theme/ts/Form';
import Panel from './Panel';

declare let Slider;

class Site {
    public static Panel:Panel = Panel;

    public static onPageHome(): void {
        $(() => {
            new Slider($('.mainslider'), {
                fixed: true,
                max: 1
            });
        })
    }

    public static onTeam($uid: JQuery): void {
        $(() => {
            const $tabs = $('.teamlist__tab', $uid);
            const $items = $('.teamlist__item');

            const setFunc = (gid: string) => {
                $tabs.removeClass('teamlist__tab--selected');
                $items.removeClass('teamlist__item--selected');

                $tabs.each((ix, el) => {
                    const $el = $(el);

                    if ($el.data('id') == gid) {
                        $el.addClass('teamlist__tab--selected');
                    }
                });

                $items.each((ix, el) => {
                    const $el = $(el);

                    if (gid == null || $el.data('id') == gid) {
                        $el.addClass('teamlist__item--selected');
                    }
                });
            };

            $tabs.each((ix, el) => {
                const $el = $(el);
                const gid = $el.data('id');

                $el.on('click', () => {
                    setFunc(gid);
                });
            });

            setFunc(null)
        });
    }

    public static discover($parent?: JQuery): void {
        let This = this;

        Utils.updateElements($parent);
    }

    private static updateFloat() {
        var $header=$('.header');

        var top=$(window).scrollTop();
        var wh=$(window).height();

        $('body').toggleClass('body--float', top > 0);
        $('body').toggleClass('body--no-float', top == 0);
        $('body').toggleClass('body--float-120', top > 120);
        $('body').toggleClass('body--no-float-120', top <= 120);
    }

    private static initHtml() {
        var $mainmenu=$('.mainmenu');

        $('.menubutton', $mainmenu).click(function(e) {
            e.preventDefault();

            $mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
            $('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
        });

        this.discover();
    }

    private static initRwd() {
        var $header = $('.header');
        $header.addClass('header--noanim');

        $(window).resize(Site.updateFloat);
        $(window).scroll(Site.updateFloat);

        Site.updateFloat();

        $header.removeClass('header--noanim');
    }

    public static evalJs(): void {
        (<any> window)._js = (h: () => void) => {
            h();
        };

        let js = (<any> window)._jslist;

        $.each(js, (k, v) => {
            v();
        });
    }

    public static init() {
        (<any> window).Site = Site;

        OpenInfo.initModule();
        ObjectFit.initModule();
        Form.initModule();

        $(document).ready(() => {
            Site.initHtml();
            Site.initRwd();
        });

        $(window).load(() => {
            $('body').addClass('body--loaded');

            setTimeout(() => {
                $('body').removeClass('body--loading');
                $('body').trigger('pageloaded');
            }, 1000);
        });
    }
}

Site.init();
