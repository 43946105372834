import Switcher from "./utils/Switcher";
import Form from "../../modules/core/forms/theme/ts/Form";

export default class Panel {
    public static onAddressForm($uid:JQuery): void {
        new Form($uid);

        (<any> window).Site.discover($uid);

        Switcher.initVal($('input[name="iscompany"]', $uid), 1, $('._js-forcompany', $uid));
    }
}
